/* @keyframes invisibleAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.outModal{
    opacity: 0.6;
    transition: .3s
}
.modal {
    animation: invisibleAnimation .3s both;
} */
.modal {
    /* overflow: auto; */
    /* height: 100vh; */
    width: 100%;
    background-color: rgba(0, 0, 0, .6);
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    margin: 0 0 100px 0;
    /* opacity: .6; */
}

.modal__content {
    display: grid;
    /* overflow: auto; */
    /* min-height: 4000px; */
    transition: .3s;
    position: relative;
    /* text-align: center; */
    font-size: 16px;
    padding: 100px 200px;
    /* border-radius: 20px; */
    background: #00b3dd;
    /* background: -webkit-gradient(linear,
            left top,
            right top,
            from(#00b3dd),
            to(#ffe600));
    background: -o-linear-gradient(left, #00b3dd 0%, #ffe600 100%);
    background: linear-gradient(to right, #00b3dd 0%, #ffe600 100%); */

}

.close {
    display: flex;
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 33px;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    text-decoration: none;
    color: #555;
    transition: .3s;
}

.close:hover {
    color: #fff;
}

.closeBack {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
    background-color: rgba(0, 0, 0, .6);
}

@media (max-width: 860px) {
    .modal__content {
        padding: 90px 100px;

    }

}

@media (max-width: 770px) {
    .modal__content {
        padding: 70px 50px;

    }

}

@media (max-width: 589px) {
    .modal__content {
        padding: 50px 20px;

    }

}

@media (max-width: 391px) {

    .modal__content {
        padding: 40px 10px;

    }

}


.title {
    font-size: 20px;
    margin: 30px 0 10px 0;
}

.text {
    font-size: 16px;
    margin: 0;
    margin-bottom: 5px;
}

.album {
    color: #fe5a5a;
}

.maxi {
    color: #ffff00;
}

.single {
    color: #00fffa;
}