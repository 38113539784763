.youtubeVideo {
	margin-bottom: 35px;
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
}

.youtubeVideo iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.video {
	margin-bottom: 35px;
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	background-color: #000000;
	/* cursor: pointer; */
}

.video__link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video__media {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
	object-fit: cover;
}

.video__button {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	/* display: none; */
	padding: 0;
	width: 68px;
	height: 48px;
	border: none;
	background-color: transparent;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.video__buttonShape {
	fill: #212121;
	fill-opacity: 0.8;
}

.video__buttonIcon {
	fill: #ffffff;
}

.video__button:focus {
	outline: none;
}

.video:hover .video__buttonShape,
.video__button:focus .video__buttonShape {
	fill: #ff0000;
	fill-opacity: 1;
}

/* Enabled */

.video--enabled {
	cursor: pointer;
}

.video--enabled .video__button {
	display: block;
}